import React, { Suspense, useMemo } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import ROUTES from '@constants/routes';
import { useAppSelector } from 'store/index';
import { selectIsAdmin, selectIsClinician, selectIsPatient, tokenParsed } from 'store/auth';
import { useFeatureFlag } from 'hooks/featureFlag';
import CircularProgressComponent from 'components/progressIndicators/CircularProgressComponent';
import Header from 'components/Header';
import ConfigurableBanner from 'components/ConfigurableBanner';
import ThemePreview from './../styles/ThemePreview';
import lazyWithRetry from './lazyWithRetry';

import LocatorTracker from 'lib/analytics/components/LocatorTracker/index';

/** BAPTIST PAGES*/
const BaptistLogin = lazyWithRetry(() => import('containers/dashboard/baptist-login'));

/** CLINICIAN ROUTE DECLARATIONS */
const Dashboard = lazyWithRetry(() => import('containers/dashboard'));
const Patient = lazyWithRetry(() => import('containers/patient'));

const Today = lazyWithRetry(() => import('containers/dashboard/today'));
const PanelCohorts = lazyWithRetry(() => import('containers/dashboard/panel-cohorts'));
const Calendar = lazyWithRetry(() => import('containers/dashboard/calendar'));
const Account = lazyWithRetry(() => import('containers/dashboard/account'));
const Support = lazyWithRetry(() => import('containers/dashboard/support'));
const Reports = lazyWithRetry(() => import('containers/dashboard/reports'));
const Team = lazyWithRetry(() => import('containers/dashboard/team'));

const Summary = lazyWithRetry(() => import('containers/patient/summary'));
const Content = lazyWithRetry(() => import('containers/patient/content'));
const Analysis = lazyWithRetry(() => import('containers/patient/analysis'));
const PatientTeam = lazyWithRetry(() => import('containers/patient/team'));
const GeneratedData = lazyWithRetry(() => import('containers/patient/generatedData'));
const PatientReports = lazyWithRetry(() => import('containers/patient/reports'));
/** /CLINICIAN ROUTE DECLARATIONS */

/** ADMIN ROUTE DECLARATIONS */
const AdminV2 = lazyWithRetry(() => import('containers/admin-v2'));
const Members = lazyWithRetry(() => import('containers/admin-v2/members'));
const MemberInvitation = lazyWithRetry(
  () => import('containers/admin-v2/members/member-invitation')
);
const AccountImport = lazyWithRetry(() => import('containers/admin-v2/members/account-import'));
const OnboardingAccounts = lazyWithRetry(
  () => import('containers/admin-v2/members/onboarding-accounts')
);
const MemberList = lazyWithRetry(() => import('containers/admin-v2/members/member-list'));
const Staff = lazyWithRetry(() => import('containers/admin-v2/staff'));
const StaffAccountInvitation = lazyWithRetry(
  () => import('containers/admin-v2/staff/account-invitation')
);
const StaffOnboardingAccounts = lazyWithRetry(
  () => import('containers/admin-v2/staff/onboarding-accounts')
);
const StaffList = lazyWithRetry(() => import('containers/admin-v2/staff/staff-list'));
const StaffAccountImport = lazyWithRetry(() => import('containers/admin-v2/staff/account-import'));
const AdminReports = lazyWithRetry(() => import('containers/admin-v2/reports'));
const AdminReportDefault = lazyWithRetry(() => import('containers/admin-v2/reports/default'));
const AdminCareTeamTimeTracking = lazyWithRetry(
  () => import('containers/admin-v2/reports/care-team-time-tracking')
);
const AdminPopulationBandStatus = lazyWithRetry(
  () => import('containers/admin-v2/reports/population-band-status')
);
const AdminPopulationCompliance = lazyWithRetry(
  () => import('containers/admin-v2/reports/population-compliance')
);
const AdminPopulationEngagement = lazyWithRetry(
  () => import('containers/admin-v2/reports/population-engagement')
);
const AdminPopulationBiometrics = lazyWithRetry(
  () => import('containers/admin-v2/reports/population-biometrics')
);
const AdminProgramCompletion = lazyWithRetry(
  () => import('containers/admin-v2/reports/program-completion')
);
const ActionSpec = lazyWithRetry(() => import('containers/admin-v2/reports/action-spec'));
/** /ADMIN ROUTES DECLARATIONS */

const AppRouter = () => {
  const isAdmin = useAppSelector(selectIsAdmin);
  const isClinician = useAppSelector(selectIsClinician);
  const isPatient = useAppSelector(selectIsPatient);
  const hasContent = useFeatureFlag('content');
  const hasAdminReport = useFeatureFlag('adminReport');
  const hasMemberReport = useFeatureFlag('memberReport');
  const tokenParsedSelector = useAppSelector(tokenParsed);
  const location = useLocation();

  const nativateTo = useMemo(() => {
    if (isPatient && (tokenParsedSelector?.extension_AllHealthID || tokenParsedSelector?.sub)) {
      return `/patient/${
        tokenParsedSelector?.extension_AllHealthID ?? tokenParsedSelector?.sub
      }/data`;
    }

    if (isAdmin) {
      return '/admin';
    }

    return '/dashboard';
  }, [isAdmin, isPatient, tokenParsedSelector?.extension_AllHealthID, tokenParsedSelector?.sub]);

  return (
    <>
      {location.pathname !== '/baptist-health' && (
        <>
          <LocatorTracker />
          <ConfigurableBanner />
          <Header hideNotifications={!isClinician} />
        </>
      )}
      <Suspense fallback={<CircularProgressComponent />}>
        <Routes>
          <Route path="/" element={<Navigate to={nativateTo} replace />} />
          <Route path="/baptist-health" element={<BaptistLogin />} />
          {isPatient ? (
            <Route path="/patient/:patientId/*" element={<Patient />}>
              <Route index element={<Navigate to="data" replace />} />
              <Route path="data" element={<GeneratedData />} />
              <Route path="analysis" element={<Analysis />} />
              {hasContent && <Route path="content" element={<Content />} />}
              {hasMemberReport && <Route path="reports" element={<PatientReports />} />}
              <Route path="*" element={<Navigate to="data" replace />} />
            </Route>
          ) : null}
          {isClinician ? (
            <>
              <Route path="/dashboard/*" element={<Dashboard />}>
                <Route index element={<Navigate to="today" replace />} />
                <Route path="today" element={<Today />} />
                <Route path="panel" element={<PanelCohorts />} />
                <Route path="calendar" element={<Calendar />} />
                <Route path="account" element={<Account />} />
                <Route path="support" element={<Support />} />
                <Route path="reports" element={<Reports />} />
                <Route path="team" element={<Team />} />
                <Route path="*" element={<Navigate to="today" replace />} />
              </Route>
              <Route path="/patient/:patientId/*" element={<Patient />}>
                <Route index element={<Navigate to="summary" replace />} />
                <Route path="summary" element={<Summary />} />
                <Route path="data" element={<GeneratedData />} />
                <Route path="analysis" element={<Analysis />} />
                {hasContent && <Route path="content" element={<Content />} />}
                {hasMemberReport && <Route path="reports" element={<PatientReports />} />}
                <Route path="team" element={<PatientTeam />} />
                <Route path="*" element={<Navigate to="summary" replace />} />
              </Route>
            </>
          ) : null}
          {isAdmin ? (
            <>
              <Route path="/admin/*" element={<AdminV2 />}>
                <Route index element={<Navigate to="members" replace />} />
                <Route path="members" element={<Members />}>
                  <Route index element={<Navigate to="account-invitation" replace />} />
                  <Route path="account-import" element={<AccountImport />} />
                  <Route path="account-invitation" element={<MemberInvitation />} />
                  <Route path="onboarding-accounts" element={<OnboardingAccounts />} />
                  <Route path="member-list" element={<MemberList />} />
                  <Route path="*" element={<Navigate to="account-invitation" replace />} />
                </Route>
                <Route path="staff" element={<Staff />}>
                  <Route index element={<Navigate to="account-invitation" replace />} />
                  <Route path="account-import" element={<StaffAccountImport />} />
                  <Route path="account-invitation" element={<StaffAccountInvitation />} />
                  <Route path="onboarding-accounts" element={<StaffOnboardingAccounts />} />
                  <Route path="staff-list" element={<StaffList />} />
                  <Route path="*" element={<Navigate to="account-invitation" replace />} />
                </Route>
                {hasAdminReport && (
                  <Route path="reports" element={<AdminReports />}>
                    <Route index element={<AdminReportDefault />} />
                    <Route path="action-spec" element={<ActionSpec />} />
                    <Route path="care-team-time-tracking" element={<AdminCareTeamTimeTracking />} />
                    <Route path="population-engagement" element={<AdminPopulationEngagement />} />
                    <Route path="population-compliance" element={<AdminPopulationCompliance />} />
                    <Route path="population-band-status" element={<AdminPopulationBandStatus />} />
                    <Route path="population-biometrics" element={<AdminPopulationBiometrics />} />
                    <Route path="program-completion" element={<AdminProgramCompletion />} />
                    <Route path="*" element={<AdminReportDefault />} />
                  </Route>
                )}
              </Route>
            </>
          ) : null}
          {process.env.NODE_ENV === 'development' ? (
            <Route path={ROUTES.theme} element={<ThemePreview />} />
          ) : null}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRouter;
