import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { getMsalConfig, setMsalInstance } from 'msal';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PublicClientApplication, Configuration } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import featureFlags from '@constants/featureFlags';

import ErrorPage from 'components/ErrorPage';
import CircularProgressComponent from 'components/progressIndicators/CircularProgressComponent';
import SnackbarProvider from 'components/SnackbarProvider';
import { FeatureFlagProvider } from 'hooks/featureFlag';
import { SbCallsProvider } from 'hooks/sbCalls';
import store from 'store/index';
import { AppConfig } from 'services/configurator';
import AppThemeProvider from 'styles/AppThemeProvider';

import AuthenticationChecker from './AuthenticationChecker';
import { getBaseUrl } from 'utils/host';

const AppInitializer = () => {
  const [pca, setPca] = useState<PublicClientApplication | null>(null);
  const [error, setError] = useState<Error | string | null>(null);
  const [config, setConfig] = useState<AppConfig | null>(null);

  useEffect(() => {
    const initializeApp = async () => {
      try {
        // get the global config for msal configuration
        const baseUrl = getBaseUrl();
        const response = await fetch(`${baseUrl}/configurator/api/v1/config?global=true`);
        if (!response.ok) {
          throw new Error(`Failed to fetch configs: ${response.status} ${response.statusText}`);
        }
        const configs = await response.json();
        const configUrl = configs?.[0];
        if (!configUrl) {
          throw new Error('No config URL found in configs');
        }
        const configResponse = await fetch(
          `${baseUrl}/configurator/api/v1/config/get?file_name=${configUrl}`
        );
        if (!configResponse.ok) {
          throw new Error(
            `Failed to fetch config data: ${configResponse.status} ${configResponse.statusText}`
          );
        }
        const configData: AppConfig = await configResponse.json();
        const params = new URLSearchParams(window.location.search);
        const idTokenHint = params.get('id_token_hint') || localStorage.getItem('id_token_hint');
        const magicPatientId = params.get('patient_id') || localStorage.getItem('patient_id');

        if (idTokenHint) {
          localStorage.setItem('id_token_hint', idTokenHint);
        }

        if (magicPatientId) {
          localStorage.setItem('patient_id', magicPatientId);
        }

        const isMagicFlow = idTokenHint;

        const msalConfig: Configuration = getMsalConfig({ ...configData, isMagicFlow });

        const msalInstance = new PublicClientApplication(msalConfig);

        // Initialize MSAL instance
        await msalInstance.initialize();

        // Set the MSAL instance in the module
        setMsalInstance(msalInstance);
        setConfig(configData);
        setPca(msalInstance);
      } catch (err) {
        console.error('Failed to initialize application', err);
        setError(err as string | Error);
      }
    };

    initializeApp();
  }, []);

  const renderApp = () => {
    if (error) {
      return <ErrorPage />;
    }

    if (!pca) {
      return <CircularProgressComponent />;
    }

    return (
      <MsalProvider instance={pca}>
        <SbCallsProvider appId="">
          <FeatureFlagProvider features={featureFlags}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CssBaseline />
              <SnackbarProvider>
                <AuthenticationChecker config={config} />
              </SnackbarProvider>
            </LocalizationProvider>
          </FeatureFlagProvider>
        </SbCallsProvider>
      </MsalProvider>
    );
  };

  return (
    <React.StrictMode>
      <Provider store={store}>
        <AppThemeProvider>{renderApp()}</AppThemeProvider>
      </Provider>
    </React.StrictMode>
  );
};

export default AppInitializer;
