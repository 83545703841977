import { useCallback, useMemo } from 'react';
import { PaletteMode } from '@mui/material';
import { useAppSelector, useAppDispatch } from 'store/index';
import { userAccount, updateUserPortalSettings } from 'store/account';
import { usePatchUserAccountMutation } from 'services/registry';
import { UserPortalSettings } from 'models/user';
import { COLOR_MODE_LOCAL_STORAGE_KEY } from '@constants/global';
import { UnitSystemType, defaultUnitSystemType } from 'utils/unitSystem';
import { setCurrentUnitSystem } from 'utils/staticUnitSystem';

export interface UserSettings {
  colorMode: PaletteMode;
  unitSystem: UnitSystemType;
}

const DEFAULT_SETTINGS: UserSettings = {
  colorMode: defaultColorMode(),
  unitSystem: defaultUnitSystemType(),
};

export default function useUserSettings(): [
  UserSettings,
  (newValues: Partial<UserSettings>) => void
] {
  const dispatch = useAppDispatch();
  const { id, portalSettings: preferences } = useAppSelector(userAccount);
  const settings = useMemo(() => settingsFromPreferences(preferences), [preferences]);

  const [patchUser] = usePatchUserAccountMutation();
  const updateSettings = useCallback(
    async (values: Partial<UserSettings>) => {
      const originalPreferences = preferences;
      const newPreferences = preferencesFromSettings(values);
      const patches = [];
      if (originalPreferences == null) {
        patches.push({
          op: 'add',
          path: '/portalSettings',
          value: {},
        });
      }
      for (const [key, value] of Object.entries(newPreferences)) {
        const preferenceExists =
          originalPreferences != null && (originalPreferences as Record<string, any>)[key] != null;
        patches.push({
          op: preferenceExists ? 'replace' : 'add',
          path: `/portalSettings/${key}`,
          value,
        });
      }

      // optimistic update
      dispatch(updateUserPortalSettings(newPreferences));

      try {
        await patchUser({
          userId: id,
          userValues: patches,
        }).unwrap();
      } catch (error) {
        console.error('Failed to update user preferences', error);
        dispatch(updateUserPortalSettings(originalPreferences));
        return;
      }

      if (values.unitSystem) {
        setCurrentUnitSystem(values.unitSystem);
      }
    },
    [id, preferences, patchUser, dispatch]
  );

  return [settings, updateSettings];
}

function settingsFromPreferences(preferences: UserPortalSettings | null | undefined): UserSettings {
  if (preferences == null) {
    return { ...DEFAULT_SETTINGS };
  }

  return {
    colorMode: (preferences.colorMode?.toLowerCase() as PaletteMode) ?? DEFAULT_SETTINGS.colorMode,
    unitSystem:
      (preferences.unitSystem?.toLowerCase() as UnitSystemType) ?? DEFAULT_SETTINGS.unitSystem,
  };
}

function preferencesFromSettings(settings: Partial<UserSettings>): UserPortalSettings {
  let result: UserPortalSettings = {};
  if (settings.colorMode) {
    result.colorMode = settings.colorMode.toUpperCase() as UserPortalSettings['colorMode'];
  }
  if (settings.unitSystem) {
    result.unitSystem = settings.unitSystem.toUpperCase() as UserPortalSettings['unitSystem'];
  }
  return result;
}

function defaultColorMode() {
  return (localStorage.getItem(COLOR_MODE_LOCAL_STORAGE_KEY) as PaletteMode) || 'light';
}
