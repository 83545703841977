import flatMap from 'lodash/flatMap';

export const CLINICAL_DATA_REPORT_DATA = [
  {
    title: 'Weight',
    tableTitle: 'Weight Scale Readings',
    description:
      "The report below visualizes your weight logs over time. It's beneficial for observing any weight fluctuations, especially when adopting a new diet plan.",
    tableHeader: [
      {
        key: 'weight',
        label: 'Weight',
      },
    ],
  },
  {
    title: 'Blood Pressure',
    tableTitle: 'Blood Pressure',
    description:
      "The report below helps visualize your blood pressure over time. It's beneficial for monitoring conditions such as hypertension.",
    tableHeader: [
      {
        key: 'systolic_data',
        label: 'Systolic (mmHg)',
      },
      {
        key: 'diastolic_data',
        label: 'Diastolic (mmHg)',
      },
    ],
  },
  {
    title: 'Blood Glucose',
    tableTitle: 'Blood Glucose',
    description:
      "The report below helps visualize your glucose levels over time. It's beneficial for observing your sugar intake and monitoring conditions such as diabetes.",
    tableHeader: [
      {
        key: 'glucose_mean',
        label: 'Mean BG (mg/dL)',
      },
      {
        key: 'glucose_min',
        label: 'Min BG (mg/dL)',
      },
      {
        key: 'glucose_max',
        label: 'Max BG (mg/dL)',
      },
      {
        key: 'glucose_count',
        label: 'Total Readings',
      },
    ],
  },
  {
    title: 'Heart Rate',
    tableTitle: 'Heart Rate',
    description:
      "The report below helps visualize your heart rate over time. It's beneficial for monitoring conditions such as atrial fibrillation or heart failure.\nHeart rate = the number of times your heart beats per minute",
    tableHeader: [
      {
        key: 'heart_rate_mean',
        label: 'Mean HR (bpm)',
      },
      {
        key: 'heart_rate_min',
        label: 'Min HR (bpm)',
      },
      {
        key: 'heart_rate_max',
        label: 'Max HR (bpm)',
      },
      {
        key: 'heart_rate_count',
        label: 'Total Readings',
      },
    ],
  },
  {
    title: 'Respiratory Rate',
    tableTitle: 'Respiratory Rate',
    description:
      "The report below helps visualize your respiratory rate over time. It's beneficial for monitoring conditions such as COPD. \nRespiratory Rate = the number of times you breathe per minute",
    tableHeader: [
      {
        key: 'resp_rate_mean',
        label: 'Mean RR (br/min)',
      },
      {
        key: 'resp_rate_min',
        label: 'Min RR (br/min)',
      },
      {
        key: 'resp_rate_max',
        label: 'Max RR (br/min)',
      },
      {
        key: 'resp_rate_count',
        label: 'Total Readings',
      },
    ],
  },
  {
    title: 'SpO2',
    tableTitle: 'Pulse Oximetry',
    description:
      "The report below helps visualize your oxygen saturation over time. It's beneficial for monitoring conditions such as COPD.\nOxygen saturation = the amount of oxygen in your red blood cells",
    tableHeader: [
      {
        key: 'sleep_spo2_median',
        label: 'Median SpO2',
      },
      {
        key: 'spo2_min',
        label: 'Min SpO2',
      },
      {
        key: 'spo2_max',
        label: 'Max SpO2',
      },
      {
        key: 'spo2_count',
        label: 'Total Readings',
      },
    ],
  },
];

export const DATA_CLINICAL_FACTS = [
  //HEART RATE
  'heart_rate_max',
  'heart_rate_min',
  'heart_rate_mean',
  'heart_rate_count',
  //RESPIRATORY RATE
  'resp_rate_max',
  'resp_rate_min',
  'resp_rate_mean',
  'resp_rate_count',
  //SPO2
  'spo2_max',
  'spo2_min',
  'sleep_spo2_median',
  'spo2_count',
  //WEIGHT
  'weight_mean',
  'weight_max',
  'weight_min',
  'weight_last',
];

export const DATA_CLINICAL_MONTH_METRICS = [
  'glucose_mean',
  'glucose_min',
  'glucose_max',
  'glucose_count',
  'heart_rate_mean',
  'heart_rate_min',
  'heart_rate_max',
  'heart_rate_count',
  'resp_rate_mean',
  'resp_rate_min',
  'resp_rate_max',
  'resp_rate_count',
  'sleep_spo2_median',
  'spo2_min',
  'spo2_max',
  'spo2_count',
];

export const CLINICAL_REPORT_HEADERS = flatMap([
  { key: 'csvDate', label: 'Date' },
  ...CLINICAL_DATA_REPORT_DATA.filter(
    (item) => item.title !== 'Weight' && item.title !== 'Blood Pressure'
  ).map((item) => item.tableHeader),
]);
