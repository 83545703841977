import { useCallback, useEffect, useState } from 'react';
import Sb, { SendBirdInstance, GroupChannel, OpenChannel } from 'sendbird';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { Notification } from 'models/notification';
import { format } from 'date-fns';
import { QueryParams, registryApi } from 'services/registry';
import { sdkSelector } from 'store/messaging/sdk';
import { useAppSelector, useAppDispatch } from 'store/index';
import { v4 } from 'uuid';
import { tokenParsed } from 'store/auth';
import { Patient, UserRole } from 'models/user';
import { formatMainName } from 'utils/formatUserInfo';
import { playSound } from 'utils/sounds';
import { addMessageToNotificationsList } from 'store/notifications';
import { currentChannelSelector } from 'store/messaging/channel';
import { isMessagingOpenSelector } from 'store/patient';
import {
  askBrowserNotificationPemission,
  showBrowserNotification,
  BrowserNotification,
} from 'utils/browserNotifications';
import messageNotificationSound from 'assets/sounds/message-notification.mp3';
import { USER_ROLE } from '@constants/team';

const useChatNotifications = () => {
  const dispatch = useAppDispatch();
  const sdk = useAppSelector(sdkSelector);
  const tokenParsedSelector = useAppSelector(tokenParsed);
  const currentChannel = useAppSelector(currentChannelSelector);
  const isMessagingOpen = useAppSelector(isMessagingOpenSelector);
  const [unreadNotifications, setUnreadNotifications] = useState<Notification[]>([]);

  const [fetchPatientsAssignToClinician, { data: patientsAssignToClinician }] =
    registryApi.endpoints.fetchPatientsAssignToClinician.useLazyQuery();

  const [fetchChatMembersProfiles, { data: membersProfiles }] =
    registryApi.endpoints.fetchChatMembersProfiles.useLazyQuery();

  const handlePatientsAssignToClinicianForNotifications = useCallback(() => {
    if (tokenParsedSelector?.extension_AllHealthID || tokenParsedSelector?.sub) {
      fetchPatientsAssignToClinician({
        userId: tokenParsedSelector?.extension_AllHealthID ?? tokenParsedSelector?.sub,
      });
    }
  }, [
    fetchPatientsAssignToClinician,
    tokenParsedSelector?.extension_AllHealthID,
    tokenParsedSelector?.sub,
  ]);

  useEffect(() => {
    if (!patientsAssignToClinician || !sdk) {
      return;
    }

    const messageReceiverId = v4();
    if (sdk.ChannelHandler) {
      const channelHandler = new sdk.ChannelHandler();

      channelHandler.onMessageReceived = (
        channel: Sb.GroupChannel | Sb.OpenChannel,
        message: Sb.UserMessage | Sb.FileMessage | Sb.AdminMessage
      ) => {
        if (!isUserOrFileMessage(message)) {
          return;
        }

        const patientAssigned = patientsAssignToClinician.find(
          (patient) => patient.id === message?.sender?.userId
        );
        if (!patientAssigned) {
          return;
        }

        const isMessageFromCurrentChannel =
          currentChannel && channel.isGroupChannel() && channel.isEqual(currentChannel);

        if (
          document.visibilityState === 'visible' &&
          isMessagingOpen &&
          isMessageFromCurrentChannel
        ) {
          // do not notify about messages in the currently active channel,
          // unless the browser tab \ window is hidden, or the chat is closed
          return;
        }

        const newNotification = notificationFromMessage(message, channel, patientAssigned);
        dispatch(addMessageToNotificationsList(newNotification));
        showBrowserNotification(browserNotificationFromNotification(newNotification));
        playSound(messageNotificationSound);
      };

      sdk.addChannelHandler(messageReceiverId, channelHandler);
    }

    return () => {
      if (sdk.removeChannelHandler) {
        sdk.removeChannelHandler(messageReceiverId);
      }
    };
  }, [patientsAssignToClinician, sdk, isMessagingOpen, currentChannel, dispatch]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (!patientsAssignToClinician || !sdk) {
        return;
      }

      const notifications = await fetchUnreadMessages(
        sdk,
        patientsAssignToClinician,
        fetchChatMembersProfiles
      );
      setUnreadNotifications(notifications);
    };

    fetchMessages();
  }, [sdk, patientsAssignToClinician, fetchChatMembersProfiles]);

  useEffect(() => {
    const hasClinicianNotifications = unreadNotifications?.some(
      (notification) =>
        notification.patientId !== notification.chatPatientId &&
        notification.patientId !== 'all_health'
    );
    if ((!hasClinicianNotifications || membersProfiles) && unreadNotifications.length > 0) {
      for (const notification of unreadNotifications) {
        let message = { ...notification };
        const senderProfile = membersProfiles?.[message.patientId ?? ''];
        if (senderProfile) {
          const senderName = `${senderProfile?.personalInfo?.firstName} ${senderProfile?.personalInfo?.lastName}`;
          const senderRoles =
            senderProfile.roles?.filter(
              (role: UserRole) => role !== 'tenant_admin' && role !== 'clinician'
            ) || [];
          const title =
            message.customType === 'system'
              ? 'ICM'
              : senderProfile.roles
              ? USER_ROLE[(senderRoles as UserRole[])[0]] || 'CTM'
              : 'CTM';
          message = {
            ...message,
            title: senderProfile ? `${senderName}${title && `, ${title}`}` : message.title,
            image: senderProfile?.personalInfo.photoUrl ?? message.image,
          };
        }
        dispatch(addMessageToNotificationsList(message));
      }
    }
  }, [unreadNotifications, membersProfiles, dispatch]);

  useEffect(() => {
    askBrowserNotificationPemission();
  }, []);

  return {
    handlePatientsAssignToClinicianForNotifications,
  };
};

export default useChatNotifications;

async function fetchUnreadMessages(
  sb: SendBirdInstance,
  patients: Patient[],
  fetchChatMembersProfiles: { (arg: QueryParams): void; (arg0: { userIds: string[] }): any }
) {
  try {
    const patientsByChannelUrl = Object.fromEntries<Patient>(
      patients
        .filter((patient) => patient.sendbirdInfo?.channel != null)
        .map((patient) => [patient.sendbirdInfo.channel!, patient])
    );

    if (isEmpty(patientsByChannelUrl)) {
      return [];
    }

    const channelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
    const lastReadNotificationTime = Number(
      (sb.currentUser.metaData as { lastReadNotificationTime?: string })?.lastReadNotificationTime
    );
    channelListQuery.includeEmpty = false;
    channelListQuery.channelUrlsFilter = Object.keys(patientsByChannelUrl);
    channelListQuery.order = 'latest_last_message';
    channelListQuery.limit = 100;

    const senderUserIds = new Set<string>();
    const notifications: Notification[] = [];

    const groupChannels = await new Promise<Sb.GroupChannel[]>((resolve, reject) => {
      channelListQuery.next((channels, error) => {
        if (error) {
          console.error('Error fetching messaging channels:', error);
          reject(error);
        } else {
          resolve(channels);
        }
      });
    });

    for (const channel of groupChannels) {
      if (channel.unreadMessageCount > 0) {
        const params = new sb.MessageListParams();
        params.isInclusive = false;
        params.prevResultSize = 0;
        params.nextResultSize = channel.unreadMessageCount;

        try {
          const messages = (await new Promise((resolve, reject) => {
            const myLastRead =
              lastReadNotificationTime && lastReadNotificationTime > channel.myLastRead
                ? lastReadNotificationTime
                : channel.myLastRead;
            channel.getMessagesByTimestamp(myLastRead, params, (messages, error) => {
              if (error) {
                reject(error);
              } else if (messages) {
                resolve(messages ?? []);
              } else {
                resolve([]);
              }
            });
          }).catch((error) => {
            console.error('Error fetching unread messages', error);
            return [];
          })) as (Sb.UserMessage | Sb.FileMessage | Sb.AdminMessage)[];

          for (const message of messages) {
            if (message.isUserMessage() || message.isFileMessage()) {
              if (
                message.sender?.userId &&
                message.sender?.userId !== patientsByChannelUrl[channel.url]?.id &&
                message.sender?.userId !== 'all_health'
              ) {
                senderUserIds.add(message.sender?.userId);
              }
              notifications.push(
                notificationFromMessage(message, channel, patientsByChannelUrl[channel.url])
              );
            }
          }
        } catch (err) {
          console.error('Error fetching unread messages for channel:', channel.url, err);
        }
      }
    }

    if (senderUserIds.size > 0) {
      try {
        await fetchChatMembersProfiles({ userIds: Array.from(senderUserIds) });
      } catch (error) {
        console.error('Error fetching sender profiles', error);
      }
    }

    return sortBy(notifications, 'createdAt');
  } catch (err) {
    console.error('Error fetching unread messages', err);
    return [];
  }
}

function notificationFromMessage(
  message: Sb.UserMessage | Sb.FileMessage,
  channel: GroupChannel | OpenChannel,
  patient?: Patient
): Notification {
  const channelData = channel.data && JSON.parse(channel.data);
  let result: Notification;
  if (message.isUserMessage()) {
    result = {
      id: String(message.messageId),
      title: patient ? formatMainName(patient.personalInfo) : message.sender?.nickname ?? '',
      image: patient?.personalInfo.photoUrl ?? null,
      description: `${message.message || ''}`,
      patientId: message.sender?.userId,
      chatPatientId: patient?.id,
      createdAt: message.createdAt,
      time: format(message.createdAt, 'h:mmaa'),
      ogMetaData: message.ogMetaData ?? null,
      chatUserId: channelData?.userId || message.sender?.userId,
      group: channelData?.group,
      customType: message.customType ?? '',
    };
  } else if (message.isFileMessage()) {
    result = {
      id: String(message.messageId),
      title: patient ? formatMainName(patient.personalInfo) : message.sender?.nickname ?? '',
      image: patient?.personalInfo.photoUrl ?? null,
      patientId: message.sender?.userId,
      chatPatientId: patient?.id,
      createdAt: message.createdAt,
      time: format(message.createdAt, 'h:mmaa'),
      isFile: message.type ? true : false,
      fileName: message.name ?? null,
      type: message.type ?? undefined,
      plainUrl: message.url ?? null,
      ogMetaData: message.ogMetaData ?? null,
      chatUserId: channelData?.userId || message.sender?.userId,
      group: channelData?.group,
      customType: message.customType ?? '',
    };
  } else {
    throw new TypeError('Only UserMessage or FileMessage can be turned into notification');
  }
  return result;
}

function browserNotificationFromNotification(appNotification: Notification): BrowserNotification {
  return {
    title: appNotification.title,
    body: (appNotification.isFile ? 'Sent a file' : appNotification.description) ?? '',
    icon: (appNotification.isFile ? appNotification.plainUrl : appNotification.image) ?? undefined,
  };
}

function isUserOrFileMessage(
  message: Sb.UserMessage | Sb.FileMessage | Sb.AdminMessage
): message is Sb.UserMessage | Sb.FileMessage {
  return message.isUserMessage() || message.isFileMessage();
}
