import React, { useMemo } from 'react';
import { LogoAllIcon } from 'assets/icons';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useStyles from './styles';
import { AppConfig } from 'services/configurator';

const HeaderAuth = ({ config }: { config: AppConfig | null }) => {
  const classes = useStyles();
  const bannerText = useMemo(() => {
    return config?.alert_banner_message;
  }, [config?.alert_banner_message]);
  return (
    <div className={classes.root} id="header">
      <AppBar
        className={classes.appBar}
        sx={{ marginTop: bannerText && bannerText.length > 0 ? '28px' : '0' }}
        elevation={0}
        position="fixed"
      >
        <Toolbar className={classes.toolbar} classes={{ root: classes.toolbarRoot }}>
          <div className={classes.linkPatientsContainer}>
            <div className={classes.leftSide} id="leftSide">
              <LogoAllIcon className={classes.icon} />
            </div>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default HeaderAuth;
