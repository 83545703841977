import { CalendarEvent, CalendarEventType } from 'models/calendarEvent';
import {
  Interval,
  addMinutes,
  differenceInMinutes,
  eachDayOfInterval,
  eachWeekOfInterval,
  format,
} from 'date-fns';

import { apiBaseQuery } from 'services/base';
import { createApi } from '@reduxjs/toolkit/query/react';
import { CALENDAR_NO_TITLE_MESSAGE } from '@constants/global';

interface EventsQueryParams {
  userId: string;
  interval: Interval;
  unreadOnly?: boolean;
}

interface DeleteEventParams {
  eventId: string;
  userId: string;
}

type ServerEventType = 'REGULAR' | 'OUT_OF_THE_OFFICE' | 'REMINDER' | 'APPOINTMENT_SLOT';

interface CalendarEventData {
  date: number;
  description?: string;
  durationMinutes: number;
  id: string;
  ownerId: string;
  notifyBefore?: number;
  actionId?: string;
  tenantId: string;
  title: string;
  type: ServerEventType;
  participants?: { id: string; accepted?: boolean }[];
}

interface CalendarEventCacheTag {
  type: 'CalendarEvent';
  id: string;
}

export const calendarApi = createApi({
  reducerPath: 'calendarApi',
  baseQuery: apiBaseQuery('/calendar/api/v1'),
  tagTypes: ['CalendarEvent'],
  endpoints(builder) {
    return {
      fetchEventsInInterval: builder.query<CalendarEvent[], EventsQueryParams>({
        query: ({ userId, interval, unreadOnly }) => ({
          url: `/users/${userId}/events`,
          method: 'GET',
          params: {
            ...(unreadOnly && { unreadOnly }),
            startDate: Number(interval.start),
            endDate: Number(interval.end),
          },
        }),
        providesTags: (result: CalendarEvent[] | undefined, _error, params) =>
          result
            ? result
                .map(({ id }) => ({
                  type: 'CalendarEvent' as const,
                  id,
                }))
                .concat(cacheTagsFromEventInterval(params.interval))
            : cacheTagsFromEventInterval(params.interval),
        transformResponse: (data: CalendarEventData[]): CalendarEvent[] => {
          return data.map((d) => calendarEventFromData(d));
        },
      }),
      createEvent: builder.mutation<CalendarEvent, CalendarEvent>({
        query: (event) => ({
          url: '/event',
          method: 'POST',
          data: dataFromCalendarEvent(event),
        }),
        invalidatesTags: (_result, _error, event) => cacheTagsFromEventInterval(event),
        transformResponse: (data: CalendarEventData): CalendarEvent => {
          return calendarEventFromData(data);
        },
      }),
      deleteEvent: builder.mutation<unknown, DeleteEventParams>({
        query: ({ eventId, userId }) => ({
          url: `/users/${userId}/events/${eventId}`,
          method: 'DELETE',
        }),
        invalidatesTags: (_result, _error, params) => [
          { type: 'CalendarEvent' as const, id: params.eventId },
        ],
      }),
      updateEvent: builder.mutation<CalendarEvent, CalendarEvent>({
        query(event) {
          const { id, ownerId } = event;
          return {
            url: `/users/${ownerId}/events/${id}`,
            method: 'PUT',
            data: dataFromCalendarEvent(event),
          };
        },
        invalidatesTags: (_result, _error, event) => [
          { type: 'CalendarEvent' as const, id: event.id },
          ...cacheTagsFromEventInterval(event),
        ],
        transformResponse: (data: CalendarEventData): CalendarEvent => {
          return calendarEventFromData(data);
        },
      }),
      markEventsAsRead: builder.mutation<CalendarEvent, { userId: string; eventIds: string[] }>({
        query(queryParams) {
          return {
            url: `/users/${queryParams.userId}/events/read`,
            method: 'PUT',
            data: { eventIds: queryParams.eventIds },
          };
        },
        invalidatesTags: ['CalendarEvent'],
      }),
    };
  },
});

export const {
  useLazyFetchEventsInIntervalQuery,
  useFetchEventsInIntervalQuery,
  useCreateEventMutation,
  useDeleteEventMutation,
  useUpdateEventMutation,
  useMarkEventsAsReadMutation,
} = calendarApi;
export const selectEventsQueryResult = (query: EventsQueryParams) =>
  calendarApi.endpoints.fetchEventsInInterval.select(query);

const ServerEventTypeToClientEventType: Record<ServerEventType, CalendarEventType> = {
  REGULAR: 'ordinary',
  OUT_OF_THE_OFFICE: 'out-of-office',
  REMINDER: 'reminder',
  APPOINTMENT_SLOT: 'appointment-slot',
};

const ClientEventTypeToServerEventType: Record<CalendarEventType, ServerEventType> = {
  ordinary: 'REGULAR',
  'out-of-office': 'OUT_OF_THE_OFFICE',
  reminder: 'REMINDER',
  'appointment-slot': 'APPOINTMENT_SLOT',
};

function calendarEventFromData(data: CalendarEventData): CalendarEvent {
  return {
    id: data.id,
    title: data.title,
    type: ServerEventTypeToClientEventType[data.type],
    description: data.description,
    start: new Date(data.date),
    end: addMinutes(new Date(data.date), data.durationMinutes),
    ownerId: data.ownerId,
    tenantId: data.tenantId,
    notifyBefore: data.notifyBefore,
    actionId: data.actionId,
    participants: data.participants,
  };
}

function dataFromCalendarEvent(event: Partial<CalendarEvent>): Partial<CalendarEventData> {
  return {
    date: Number(event.start),
    description: event.description,
    durationMinutes:
      event.end && event.start ? differenceInMinutes(event.end, event.start) : undefined,
    title: event.title || CALENDAR_NO_TITLE_MESSAGE,
    type: event.type && ClientEventTypeToServerEventType[event.type],
    ownerId: event.ownerId,
    tenantId: event.tenantId,
    notifyBefore: event.notifyBefore,
    actionId: event.actionId,
    participants: event.participants,
  };
}

function cacheTagsFromEventInterval(interval: Interval): CalendarEventCacheTag[] {
  const days = eachDayOfInterval(interval);
  const weeks = eachWeekOfInterval(interval);

  return [
    ...days.map((d) => `DAY:${format(d, 'd/M/y')}`),
    ...weeks.map((w) => `WEEK:${format(w, 'w/y')}`),
  ].map((id) => ({
    type: 'CalendarEvent' as const,
    id,
  }));
}
