import { createApi } from '@reduxjs/toolkit/query/react';
import { Roles, TenantAPI } from './../../models/user';
import { apiBaseQuery } from 'services/base';

interface GetUserTenant {
  userId: string;
  type: 'clinicians' | 'patients';
}

interface GetUserRoles {
  clinicianIds: string[];
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: apiBaseQuery('/user/api/v3'),
  tagTypes: ['User', 'CliniciansRoles'],
  endpoints(builder) {
    return {
      fetchUserTenant: builder.query<TenantAPI[], GetUserTenant>({
        query: (queryParams) => ({
          url: `/users/${queryParams.type}/${queryParams.userId}/tenants`,
          method: 'GET',
        }),
        providesTags: ['User'],
      }),
      fetchCliniciansRoles: builder.query<Roles[], GetUserRoles>({
        query: (queryParams) => ({
          url: `/users/clinicians/roles`,
          method: 'POST',
          data: {
            userIds: queryParams.clinicianIds,
          },
        }),
        providesTags: ['CliniciansRoles'],
      }),
    };
  },
});

export const { useFetchUserTenantQuery, useLazyFetchCliniciansRolesQuery } = userApi;
