import React from 'react';
import { Avatar, Box } from '@mui/material';
import { AvatarIcon, ChevronRightIcon } from 'assets/icons';
import { Notification, NotificationType } from 'models/notification';
import {
  deleteActionsFromNotificationsList,
  deleteCalendarEventFromNotificationsList,
  deletePatientMessagesFromNotificationsList,
} from 'store/notifications';
import { setInitialSelectedMessageId } from 'store/messaging/channel';
import { setIsCommunicationsOpen, setSelectedCommunicationTab } from 'store/patient';

import { CustomColors } from 'styles/theme';
import LinkPreview from 'components/LinkPreview';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import StyledListItem from '../../styledComponents/StyledListItem';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from 'store/index';
import { useNavigate } from 'react-router-dom';
import useChatMessageFormatter from 'hooks/useChatMessageFormatter';
import CommunicationTab from '@constants/communications';
import { useMarkEventsAsReadMutation } from 'services/calendar';
import { userIdSelector } from 'store/account';

interface NotificationItemProps {
  color: CustomColors;
  event: Notification;
  type: NotificationType;
  handleClose: () => void;
}

const NotificationItem = ({ color, event, type, handleClose }: NotificationItemProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const chatMessageFormatter = useChatMessageFormatter();
  const [markEventsAsRead] = useMarkEventsAsReadMutation();
  const userId = useAppSelector(userIdSelector);

  const handleClick = () => {
    switch (type.toLocaleLowerCase()) {
      case 'actions':
        dispatch(deleteActionsFromNotificationsList(event.id));
        navigate(`/patient/${event.patientId}/summary?actionId=${event.id}`);
        break;
      case 'calendar':
        dispatch(deleteCalendarEventFromNotificationsList(event.id));
        markEventsAsRead({ userId, eventIds: [event.id] });
        navigate('/dashboard/calendar', {
          state: event.startsAt ? { selectedDate: event.startsAt } : {},
        });
        break;
      case 'messages':
        dispatch(deletePatientMessagesFromNotificationsList(event.chatUserId));
        dispatch(setInitialSelectedMessageId(Number(event.id)));
        navigate(`/patient/${event.chatUserId}/summary`);
        dispatch(setIsCommunicationsOpen(true));
        dispatch(setSelectedCommunicationTab(CommunicationTab.Messaging));
        break;
    }

    handleClose();
  };

  return (
    <StyledListItem
      key={event.id}
      color={color}
      dense
      disableGutters
      divider
      onClick={handleClick}
      isCalendarItem={type === 'calendar' ? true : undefined}
    >
      {type === 'calendar' ? null : (
        <ListItemAvatar
          sx={{ padding: 0, margin: 0, width: '0rem', display: 'flex', justifyContent: 'center' }}
        >
          {event.image ? (
            <Avatar
              alt={event.title}
              src={`${event.image}`}
              sx={{ width: 30, height: 30, fontSize: '3rem', padding: 0, margin: 0 }}
            />
          ) : (
            <AvatarIcon sx={{ fontSize: '3rem', padding: 0, margin: 0, width: 'auto' }} />
          )}
        </ListItemAvatar>
      )}
      <ListItemText
        primary={
          <>
            {type === 'calendar' ? (
              <Typography className="eventTitle" variant="header12">
                {event.title}
              </Typography>
            ) : (
              <Typography variant="para12">{event.title}</Typography>
            )}
            {event.time && (
              <Box>
                <Typography variant="para12">{event.time}</Typography>
              </Box>
            )}
          </>
        }
        secondary={
          <>
            {type === 'actions' ? (
              <Typography
                className="eventTitle"
                variant="header12"
                sx={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
              >
                {event.description}
              </Typography>
            ) : (
              <>
                {event.isFile && event.plainUrl ? (
                  <LinkPreview
                    url={event.plainUrl}
                    message={{
                      type: event.type,
                      ogMetaData: event.ogMetaData,
                      name: event.fileName,
                      size: event.size,
                    }}
                  />
                ) : (
                  <>
                    {event.description && (
                      <Typography
                        variant="para12"
                        onClick={(e) => {
                          if (e.target instanceof HTMLAnchorElement) {
                            e.stopPropagation();
                          }
                        }}
                        dangerouslySetInnerHTML={{
                          __html: chatMessageFormatter(event.description),
                        }}
                      />
                    )}
                    {!!event.ogMetaData && (
                      <LinkPreview
                        url={event.ogMetaData.url}
                        message={{
                          type: event.type,
                          ogMetaData: event.ogMetaData,
                          name: event.fileName,
                          size: event.size,
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </>
        }
      />
      <ListItemSecondaryAction className="eventAction">
        <ChevronRightIcon />
      </ListItemSecondaryAction>
    </StyledListItem>
  );
};

export default NotificationItem;
