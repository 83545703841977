import { useCallback, useEffect, useRef, useState } from 'react';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';

import { currentToken, selectedTenant, tokenParsed } from 'store/auth';
import { registryApi } from 'services/registry';
import { useAppSelector } from 'store/index';

class RetriableError extends Error {}
class FatalError extends Error {}

export type SSEvariant = 'ACTION' | 'ACTION_BY_USER';

export interface Content {
  actionCategory: string;
  actionTitle: string;
  actionType: string;
  assigneeRole: string;
  createdAt: number;
  createdBy: string;
  description: string;
  evidence: [];
  id: string;
  patientId: string;
  priority: string;
  status: { status: string; reason: string; timestamp: number; userId: string };
  tasks: [];
  tenantId: string;
  _id: {
    counter: number;
    date: number;
    machineIdentifier: number;
    processIdentifier: number;
    time: number;
    timeSecond: number;
    timestamp: number;
  };
  contentType: string;
}

const useActionsNotifications = () => {
  const [newSSEMessage, setNewSSEMessage] = useState<{
    content: Content;
    contentType: SSEvariant;
  } | null>(null);
  const tenant = useAppSelector(selectedTenant);
  const token = useAppSelector(currentToken);
  const tokenParsedSelector = useAppSelector(tokenParsed);
  const controllersRef = useRef<Record<SSEvariant, AbortController | null>>({
    ACTION: null,
    ACTION_BY_USER: null,
  });

  const [fetchPatientForNotifications] =
    registryApi.endpoints.fetchUserPatientForNotifications.useLazyQuery();

  const handleSSE = useCallback(
    async (variant: SSEvariant) => {
      // Abort the existing controller for this variant
      if (controllersRef.current?.[variant]) {
        controllersRef.current?.[variant]?.abort();
      }

      const ctrl = new AbortController();
      controllersRef.current[variant] = ctrl;

      if (
        token &&
        (tokenParsedSelector?.extension_AllHealthID || tokenParsedSelector?.sub) &&
        tenant
      ) {
        const variants = {
          ACTION: `/notification/akka_actor/api/v1/subscribe/tenant/${
            tenant.tenant_ID
          }/role/${String(tenant.role_ID).toUpperCase()}/sse`,
          ACTION_BY_USER: `/notification/akka_actor/api/v1/subscribe/tenant/${
            tenant.tenant_ID
          }/user/${tokenParsedSelector?.extension_AllHealthID ?? tokenParsedSelector?.sub}/sse`,
        };

        try {
          await fetchEventSource(variants[variant], {
            headers: {
              Authorization: `Bearer ${token}`,
              client: 'webapp',
              Connection: 'keep-alive',
            },
            method: 'GET',
            openWhenHidden: true,
            signal: ctrl.signal,
            async onopen(response) {
              if (
                response.ok &&
                response.headers.get('content-type')?.includes(EventStreamContentType)
              ) {
                return;
              } else if (
                response.status >= 400 &&
                response.status < 500 &&
                response.status !== 429
              ) {
                throw new FatalError();
              } else {
                throw new RetriableError();
              }
            },
            onmessage(msg) {
              if (msg.data[0] === '{') {
                const data = JSON.parse(msg.data) || null;
                const newMessage = {
                  content: JSON.parse(data.content) || null,
                  contentType: data.contentType,
                };
                setNewSSEMessage(newMessage);
              }
              if (msg.event === 'FatalError') {
                throw new FatalError(msg.data);
              }
            },
            onclose() {
              throw new RetriableError();
            },
            onerror(err) {
              if (err instanceof FatalError) {
                throw err;
              } else {
                return 600000;
              }
            },
          });
        } catch (e) {
          console.error('[SSE] Error during fetchEventSource:', e);
        }
      }
    },
    [tenant, token, tokenParsedSelector?.extension_AllHealthID, tokenParsedSelector?.sub]
  );

  useEffect(() => {
    if (newSSEMessage && newSSEMessage.contentType === 'ACTION') {
      fetchPatientForNotifications({
        patientId: newSSEMessage.content.patientId,
        notification: {
          id: newSSEMessage.content.id,
          title: '',
          description: newSSEMessage.content.description,
          image: '',
          patientId: newSSEMessage.content.patientId,
        },
        notificationVariant: 'ACTION',
      });
    }
  }, [fetchPatientForNotifications, newSSEMessage]);

  return { handleSSE, newSSEMessage };
};

export default useActionsNotifications;
