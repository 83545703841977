import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { apiBaseQuery } from 'services/base';

interface TickItem {
  user_id: string;
  first_tick: number | null;
  first_band_sync: number | null;
}

interface TicksResponse {
  ticks: TickItem[];
}

const fetchOnboardedDates = async ({
  queryApi,
  extraOptions,
  patientIds,
}: {
  queryApi: BaseQueryApi;
  extraOptions: {};
  patientIds: string[];
}) => {
  const params = new URLSearchParams();
  params.append('user_ids', `${patientIds}`);

  const firstTicksResponse = await apiBaseQuery('/ticks-store/api/v1')(
    {
      url: `users/first-ticks?${params.toString()}`,
      method: 'GET',
    },
    queryApi,
    extraOptions
  );

  if (firstTicksResponse?.data) {
    const { ticks } = firstTicksResponse?.data as TicksResponse;
    const onboardedDatesByUser = getOnboardedDatesByUser(ticks);
    return onboardedDatesByUser;
  }
  return {};
};

const getOnboardedDatesByUser = (items: TickItem[]) => {
  const onboardedDatesByUser: Record<string, string> = items.reduce((prev, current) => {
    if (current.first_band_sync) {
      return {
        ...prev,
        [current.user_id]: new Date(current.first_band_sync).toISOString(),
      };
    }
    return prev;
  }, {});
  return onboardedDatesByUser;
};

export default fetchOnboardedDates;
