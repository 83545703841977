import { Patient, User } from 'models/user';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Dictionary } from 'lodash';
import { RootState } from 'store/rootReducer';
import { getActivityCompliance } from 'utils/compliance';
import { registryApi } from 'services/registry';
import CommunicationTab from '@constants/communications';
import { calculateBMI } from 'utils/formatUserInfo';
import {
  BandStatusReportRawData,
  ClinicalReportPatientData,
  EngagementReportRawData,
  PeriodItem,
} from 'models/reports';
import { memberReportsApi } from 'services/userReports/memberReports';

export interface InitialPatientStateProps {
  patientData?: Patient;
  isLoading: boolean;
  teams: { primaryTeam: User[] | undefined; supportTeam: User[] | undefined } | undefined;
  chatMembersProfileById?: Dictionary<User> | null | undefined;
  isCommunicationsOpen: boolean;
  selectedCommunicationTab: CommunicationTab;
  reports: {
    openSummary: boolean;
    periodSelected?: PeriodItem;
    summary: {
      currentMemberReport?: ClinicalReportPatientData;
    };
    engagement: {
      items: EngagementReportRawData[];
    };
    bandStatus: {
      items: BandStatusReportRawData[];
    };
  };
}

const initialState: InitialPatientStateProps = {
  patientData: undefined,
  isLoading: true,
  teams: undefined,
  chatMembersProfileById: undefined,
  isCommunicationsOpen: true,
  selectedCommunicationTab: CommunicationTab.Messaging,
  reports: {
    openSummary: false,
    summary: {
      currentMemberReport: undefined,
    },
    engagement: {
      items: [],
    },
    bandStatus: {
      items: [],
    },
  },
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    clearPatientData: () => ({
      ...initialState,
      patientData: undefined,
    }),
    setChatMembersProfileById: (
      state,
      action: PayloadAction<Dictionary<User> | null | undefined>
    ) => {
      return {
        ...state,
        chatMembersProfileById: action.payload,
      };
    },
    setIsCommunicationsOpen: (state, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isCommunicationsOpen: action.payload,
      };
    },
    setSelectedCommunicationTab: (state, action: PayloadAction<CommunicationTab>) => {
      return {
        ...state,
        selectedCommunicationTab: action.payload,
      };
    },
    // Reports
    setPatientReportPeriod: (state, action: PayloadAction<PeriodItem | undefined>) => {
      state.reports.periodSelected = action.payload;
    },
    setOpenMemberSummary: (state, action: PayloadAction<boolean>) => {
      state.reports.openSummary = action.payload;
    },
    clearPatientReports: (state) => {
      state.reports = { ...initialState.reports };
    },
    // End Reports
    clearPatientTeams: (state) => {
      state.teams = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(registryApi.endpoints.fetchPatientTab.matchPending, (state) => {
      state.isLoading = true;
    });
    builder.addMatcher(
      registryApi.endpoints.fetchPatientTab.matchFulfilled,
      (state, { payload }) => {
        const medicationCompliance = getActivityCompliance(
          payload.activitiesData,
          'LOGGING_MEDICATION',
          payload.activityDays
        );
        const mealsCompliance = getActivityCompliance(
          payload.activitiesData,
          'LOGGING_FOOD',
          payload.activityDays
        );
        state.patientData = {
          ...payload,
          facts: {
            ...payload.factsData,
            bmiC:
              payload?.bioInfo?.heightInMeters && payload?.bioInfo?.weightInKilograms
                ? calculateBMI(payload.bioInfo?.heightInMeters, payload.bioInfo.weightInKilograms)
                : undefined,
          },
          compliance: {
            weight: {
              totalDays: payload.activityDays,
              complianceDays: payload.complianceData.weightCompliance,
              compliancePercentage: `${Math.round(
                (payload.complianceData.weightCompliance / payload.activityDays) * 100
              )}%`,
            },
            band: {
              totalDays: payload.complianceDays,
              complianceDays: payload.complianceData.bandCompliance,
              compliancePercentage: `${Math.round(
                (payload.complianceData.bandCompliance / payload.complianceDays) * 100
              )}%`,
              status: payload.complianceData?.bandStatus,
            },
            app: {
              totalDays: payload.complianceDays,
              complianceDays: payload.complianceData.appCompliance,
              compliancePercentage: `${Math.round(
                (payload.complianceData.appCompliance / payload.complianceDays) * 100
              )}%`,
            },
            medication: {
              totalDays: payload.activityDays,
              complianceDays: medicationCompliance.complianceDays,
              compliancePercentage: medicationCompliance.compliancePercentage,
            },
            meals: {
              totalDays: payload.activityDays,
              complianceDays: mealsCompliance.complianceDays,
              compliancePercentage: mealsCompliance.compliancePercentage,
            },
          },
        };
        state.isLoading = false;
      }
    );
    builder.addMatcher(registryApi.endpoints.fetchPatientTab.matchRejected, (state) => {
      state.isLoading = false;
    });

    builder.addMatcher(
      registryApi.endpoints.fetchPatientTeamsMembers.matchFulfilled,
      (state, { payload }) => {
        state.teams = {
          primaryTeam: payload.primaryTeam,
          supportTeam: payload.supportTeam,
        };
      }
    );

    builder.addMatcher(
      registryApi.endpoints.fetchChatMembersProfiles.matchFulfilled,
      (state, { payload }) => {
        state.chatMembersProfileById = {
          ...(state.chatMembersProfileById ?? {}),
          ...(payload ?? {}),
        } as Dictionary<User>;
      }
    );

    //Member Reports
    builder.addMatcher(
      memberReportsApi.endpoints.fetchMemberEngagementReport.matchFulfilled,
      (state, { payload }) => {
        state.reports.engagement.items = payload;
      }
    );
    builder.addMatcher(
      memberReportsApi.endpoints.fetchMemberBandStatusReport.matchFulfilled,
      (state, { payload }) => {
        state.reports.bandStatus.items = payload;
      }
    );
    builder.addMatcher(
      memberReportsApi.endpoints.fetchMemberSummaryReport.matchFulfilled,
      (state, { payload }) => {
        state.reports.summary.currentMemberReport = payload;
      }
    );
    builder.addMatcher(
      memberReportsApi.endpoints.fetchDataSummaryReport.matchFulfilled,
      (state, { payload }) => {
        state.reports.summary.currentMemberReport = payload;
      }
    );
  },
});

export const {
  clearPatientData,
  setChatMembersProfileById,
  setIsCommunicationsOpen,
  setSelectedCommunicationTab,
  setPatientReportPeriod,
  clearPatientReports,
  setOpenMemberSummary,
  clearPatientTeams,
} = patientSlice.actions;
export const patientDataSelector = (state: RootState) => state.patient.patientData;
export const patientTimezoneSelector = (state: RootState) =>
  state.patient.patientData?.languageRegion.timezone;
export const isLoadingPatientDataSelector = ({ patient }: RootState) => patient.isLoading;
export const patientTeamsSelector = (state: RootState) => state.patient.teams;
export const patientChatMembersProfileById = (state: RootState) =>
  state.patient.chatMembersProfileById;
export const isCommunicationsOpenSelector = (state: RootState) =>
  state.patient.isCommunicationsOpen;
export const selectedCommunicationTabSelector = (state: RootState) =>
  state.patient.selectedCommunicationTab;
export const isMessagingOpenSelector = (state: RootState) =>
  state.patient.isCommunicationsOpen &&
  state.patient.selectedCommunicationTab === CommunicationTab.Messaging;
export const patientReportsSelector = (state: RootState) => state.patient.reports;
export const patientReportPeriodSelector = (state: RootState) =>
  state.patient.reports.periodSelected;
export const openMemberSummarySelector = (state: RootState) => state.patient.reports.openSummary;
export const currentMemberReportSelector = (state: RootState) =>
  state.patient.reports.summary.currentMemberReport;

export default patientSlice.reducer;
