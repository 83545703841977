import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { CircleXIcon } from 'assets/icons';
import { useAppSelector } from 'store/index';
import { configSelector } from 'store/account';
import { AppConfig } from 'services/configurator';

const ConfigurableBanner = ({ config }: { config?: AppConfig | null }) => {
  const appConfig = useAppSelector(configSelector);
  const bannerText = useMemo(() => {
    return config?.alert_banner_message || appConfig?.app?.alert_banner_message;
  }, [config?.alert_banner_message, appConfig?.app?.alert_banner_message]);

  return bannerText && bannerText.length > 0 ? (
    <Box
      sx={{
        backgroundColor: 'custom.cyan',
        color: 'custom.white',
        height: '30px',
        width: '100%',
        gap: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant="para12">{bannerText}</Typography> <CircleXIcon />
    </Box>
  ) : null;
};

export default ConfigurableBanner;
