import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';

type TokenRefreshCallback = (token: string, claims: any) => void;
export const startTokenRefreshTimer = (
  instance: IPublicClientApplication,
  account: AccountInfo,
  exp: number | undefined,
  onTokenRefreshed: TokenRefreshCallback
) => {
  const now = Math.floor(Date.now() / 1000);
  const fallbackExp = now + 15 * 60;
  const actualExp = exp ?? fallbackExp;

  const secondsUntilRefresh = actualExp - now - 120; // 2 minutes early
  if (secondsUntilRefresh <= 0) {
    return setTimeout(async () => {
      try {
        const result = await instance.acquireTokenSilent({
          account,
          scopes: ['openid'],
        });
        onTokenRefreshed(result.idToken, result.idTokenClaims);
      } catch (e) {
        console.error('Silent token refresh failed', e);
      }
    }, 1000); // immediately in 1s
  }

  const refreshTimeInMs = secondsUntilRefresh * 1000;

  return setTimeout(async () => {
    try {
      const result = await instance.acquireTokenSilent({
        account,
        scopes: ['openid'],
      });

      onTokenRefreshed(result.idToken, result.idTokenClaims);
    } catch (e) {
      console.error('Silent token refresh failed', e);
    }
  }, refreshTimeInMs);
};
