import { createApi } from '@reduxjs/toolkit/query/react';
import { R4 } from '@ahryman40k/ts-fhir-types';
import { MedicalHistory } from 'models/fhir';
import { apiBaseQuery } from 'services/base';

export interface QueryParams {
  userId: string;
}

export const fhirApi = createApi({
  reducerPath: 'fhirApi',
  baseQuery: apiBaseQuery('/fhirservice/api/v1.0'),
  tagTypes: ['MedicalHistory'],
  endpoints(builder) {
    return {
      fetchPatientMedicalHistory: builder.query<MedicalHistory, QueryParams>({
        async queryFn(queryParams, queryApi, extraOptions, baseQuery) {
          const [proceduresResponse, medicationsResponse, conditionsResponse] = await Promise.all([
            baseQuery({
              url: `/patients/${queryParams.userId}/procedures`,
              method: 'GET',
            }),
            baseQuery({
              url: `/patients/${queryParams.userId}/prescriptions`,
              method: 'GET',
            }),
            baseQuery({
              url: `/patients/${queryParams.userId}/conditions`,
              method: 'GET',
            }),
          ]);

          const procedureResource = proceduresResponse.data as R4.IProcedure[];
          const medicationsResource = medicationsResponse.data as R4.IMedicationRequest[];
          const conditionsResource = conditionsResponse.data as R4.ICondition[];

          const surgicals = proceduresResponse?.error
            ? new Set([])
            : procedureResource?.reduce((arrayOfProcedures, procedure) => {
                if (
                  procedure.category?.text?.includes('Surgical Procedures') &&
                  procedure.code?.text
                ) {
                  arrayOfProcedures.add(procedure.code.text);
                }
                return arrayOfProcedures;
              }, new Set<string>([]));

          const medications = medicationsResponse?.error
            ? new Set([])
            : medicationsResource?.reduce((arrayOfMedication, medication) => {
                if (medication.medicationReference?.display) {
                  arrayOfMedication.add(medication.medicationReference.display);
                }
                return arrayOfMedication;
              }, new Set<string>([]));

          const conditions = conditionsResponse?.error
            ? new Set([])
            : conditionsResource?.reduce((arrayOfConditions, condition) => {
                if (condition?.code) {
                  arrayOfConditions.add(condition.code as string);
                }
                return arrayOfConditions;
              }, new Set<string>([]));

          return {
            data: {
              diagnoses: Array.from(conditions),
              medications: Array.from(medications),
              surgical: Array.from(surgicals),
            },
          };
        },
        providesTags: ['MedicalHistory'],
      }),
    };
  },
});

export const { useFetchPatientMedicalHistoryQuery, useLazyFetchPatientMedicalHistoryQuery } =
  fhirApi;
