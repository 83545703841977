import { differenceInDays, endOfDay, format, startOfDay } from 'date-fns';
import { groupBy, isEmpty, orderBy, sumBy, uniq } from 'lodash';
import { Program, ProgramCompletionRawData } from 'models/programCompletionReport';
import { ExternalIdColumn, PeriodItem } from 'models/reports';

interface CsvHeaderProps {
  externalIdColumns?: ExternalIdColumn[];
}

export const getCsvHeaders = ({ externalIdColumns }: CsvHeaderProps) => {
  return [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Patient ID', key: 'userId' },
    ...(externalIdColumns ?? []),
    { label: 'Account Status', key: 'userStatus' },
    { label: 'Account Created', key: 'createdDate' },
    { label: 'Member Since', key: 'onboardedDate' },
    { label: 'Cohorts', key: 'cohorts' },
    { label: 'Program ID', key: 'programId' },
    { label: 'Program Name', key: 'programName' },
    { label: 'Program Started', key: 'programCreatedDate' },
    { label: 'Program Completion (%)', key: 'programCompletion' },
    { label: '# Modules', key: 'modulesCount' },
    { label: '# Modules Completed', key: 'modulesCompleted' },
    { label: '# Insights', key: 'insightsCount' },
    { label: '# Insights Completed', key: 'insightsCompleted' },
    { label: '# Pledges', key: 'pledgesCount' },
    { label: '# Pledges Completed', key: 'pledgesCompleted' },
    { label: '# Surveys', key: 'surveysCount' },
    { label: '# Surveys Completed', key: 'surveysCompleted' },
    { label: 'Program Completed', key: 'programCompletedDate' },
    { label: 'Program Canceled', key: 'programCanceledDate' },
  ];
};

export const reportFromProgram = (
  programs: Program[],
  period: PeriodItem
): ProgramCompletionRawData[] => {
  return orderBy(
    programs.map((program) => ({
      userId: program.patient_id,
      userStatus: program.account_status,
      firstName: program.first_name,
      lastName: program.last_name,
      mrn: program.mrn,
      employeeId: program.employee_id,
      createdAt: program.account_created,
      createdDate: program.account_created
        ? format(program.account_created * 1000, 'MM/dd/yyyy')
        : null,
      onboardedAt: program.member_since,
      onboardedDate: program.member_since
        ? format(program.member_since * 1000, 'MM/dd/yyyy')
        : null,
      programId: `${program.program_id}`,
      programName: program.program_name,
      programCompletion: program.program_completion ?? null,
      modulesCount: program.n_modules ? program.n_modules : null,
      modulesCompleted: program.n_modules_completed ? program.n_modules_completed : null,
      insightsCount: program.n_insights ? program.n_insights : null,
      insightsCompleted: program.n_insights_completed ? program.n_insights_completed : null,
      pledgesCount: program.n_pledges ? program.n_pledges : null,
      pledgesCompleted: program.n_pledges_completed ? program.n_pledges_completed : null,
      surveysCount: program.n_surveys ? program.n_surveys : null,
      surveysCompleted: program.n_surveys_completed ? program.n_surveys_completed : null,
      programCreated: program.program_started,
      programCreatedDate: format(program.program_started * 1000, 'MM/dd/yyyy'),
      programCompleted: program.program_completed,
      programCompletedDate: program.program_completed
        ? format(program.program_completed * 1000, 'MM/dd/yyyy')
        : null,
      cohorts: program.cohorts,
      programCanceled: program.program_canceled,
      programCanceledDate: program.program_canceled
        ? format(program.program_canceled * 1000, 'MM/dd/yyyy')
        : null,
      daysUntilCompleted: program.program_completed
        ? differenceInDays(
            endOfDay(program.program_completed * 1000),
            startOfDay(program.program_started * 1000)
          )
        : null,
      daysInProgress:
        !program.program_completed && !program.program_canceled
          ? differenceInDays(period.value.end, startOfDay(program.program_started * 1000))
          : null,
    })),
    [(report: ProgramCompletionRawData) => report.lastName?.toLowerCase().replace(/[^\w]/gi, '')]
  );
};

export const getProgramsSummaryData = (programs: ProgramCompletionRawData[]) => {
  const patients = new Set(programs.map((p) => p.userId));
  const totalPatientsInProgress = uniq(
    programs.filter((p) => !p.programCanceledDate && !p.programCompletedDate).map((p) => p.userId)
  ).length;
  const totalPatientsInvolved = [...patients].length;

  const byPrograms = groupBy(programs, 'programId');
  const byDaysCompleted = groupBy(
    programs.filter((p) => !!p.programCompletedDate),
    'daysUntilCompleted'
  );
  const byDaysInProgress = groupBy(
    programs.filter((p) => !p.programCompletedDate && !p.programCanceledDate),
    (program) => `"${program.daysInProgress}-${program.programCompletion}"`
  );

  const programsCompleted = programs.filter((program) => !!program.programCompleted).length;
  const programsInProgress = programs.filter(
    (program) => !program.programCompleted && !program.programCanceled
  ).length;

  const summary = !isEmpty(byPrograms)
    ? Object.keys(byPrograms).map((programId) => {
        const programs = byPrograms[programId];
        const program = programs[0];
        const modules = sumBy(programs, 'modulesCompleted');
        const insights = sumBy(programs, 'insightsCompleted');
        const pledges = sumBy(programs, 'pledgesCompleted');
        const surveys = sumBy(programs, 'surveysCompleted');

        return {
          programId,
          programName: program.programName,
          modules,
          insights,
          pledges,
          surveys,
          participants: uniq(byPrograms[programId].map((program) => program.userId)).length,
        };
      })
    : [];

  const chartData = !isEmpty(byPrograms)
    ? Object.keys(byPrograms).map((programId) => {
        const programCompletedPatients = byPrograms[programId]
          .filter((program) => !!program.programCompletedDate)
          .map((program) => program.userId);

        const programInProgressPatients = byPrograms[programId]
          .filter((program) => !program.programCompletedDate && !program.programCanceledDate)
          .map((program) => program.userId);

        return {
          completed: programCompletedPatients.length,
          inProgress: programInProgressPatients.length,
          programName: byPrograms[programId][0].programName,
        };
      })
    : [];

  const chartCompletedDaysData = !isEmpty(byDaysCompleted)
    ? orderBy(
        Object.keys(byDaysCompleted).map((daysUntilCompleted) => {
          const patients = byDaysCompleted[daysUntilCompleted];
          return {
            daysUntilCompleted: Number(daysUntilCompleted),
            patients: patients.length,
          };
        }),
        'daysUntilCompleted'
      )
    : [];

  const chartInProgressDaysData = !isEmpty(byDaysInProgress)
    ? orderBy(
        Object.keys(byDaysInProgress).map((key) => {
          const patients = byDaysInProgress[key];
          const completion = patients[0].programCompletion;
          const daysInProgress = patients[0].daysInProgress;
          return {
            daysInProgress,
            completion,
            patients: patients.length,
          };
        }),
        'daysInProgress'
      )
    : [];

  return {
    totalPatientsInvolved,
    totalPatientsInProgress,
    programsCompleted,
    programsInProgress,
    chartData,
    chartCompletedDaysData,
    chartInProgressDaysData,
    summary,
  };
};
