import { PayloadAction, Slice, SliceCaseReducers, createSlice } from '@reduxjs/toolkit';
import { loadState, saveState } from 'utils/localStorage';

import { NotificationList, Notification } from 'models/notification';
import { RootState } from 'store/rootReducer';
import { uniqBy } from 'lodash';

export interface NotificationState {
  notificationList: NotificationList[];
  generalBadge: number;
  uid?: string;
  tenantId?: string;
}
const initialState: NotificationState = {
  notificationList: [
    {
      id: 'notification-1',
      badge: 0,
      type: 'calendar',
      title: 'Calendar',
      list: [],
    },
    {
      id: 'notification-2',
      badge: 0,
      type: 'actions',
      title: 'Actions',
      list: [],
    },
    {
      id: 'notification-3',
      badge: 0,
      type: 'messages',
      title: 'Messages',
      list: [],
    },
  ],
  generalBadge: 0,
  uid: undefined,
  tenantId: undefined,
};
const notificationSlice: Slice<
  NotificationState,
  SliceCaseReducers<NotificationState>,
  'notifications'
> = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setInitialNotifications: (state, action: PayloadAction<NotificationState>) => {
      const newState = {
        ...state,
        notificationList: action.payload?.notificationList || [
          {
            id: 'notification-1',
            badge: 0,
            type: 'calendar',
            title: 'Calendar',
            list: [],
          },
          {
            id: 'notification-2',
            badge: 0,
            type: 'actions',
            title: 'Actions',
            list: [],
          },
          {
            id: 'notification-3',
            badge: 0,
            type: 'messages',
            title: 'Messages',
            list: [],
          },
        ],
        generalBadge: action.payload?.generalBadge || 0,
      };
      return newState;
    },
    addCalendarEventToNotificationsList: (state, action: PayloadAction<Notification>) => {
      const newList = uniqBy([...state.notificationList[0].list, action.payload], 'id');
      let calendarBadge = 0;
      newList.forEach((item) => {
        if (item.clicked === false) {
          calendarBadge++;
        }
      });
      const newCalendarEventList = {
        ...state.notificationList[0],
        list: newList,
        badge: calendarBadge,
      };
      const newState = {
        ...state,
        notificationList: [
          newCalendarEventList,
          state.notificationList[1],
          state.notificationList[2],
        ],
        generalBadge:
          (newCalendarEventList.badge || 0) +
          (state.notificationList[1].badge || 0) +
          (state.notificationList[2].badge || 0),
      };
      saveNotificationsToLocalStorage(newState);
      return newState;
    },
    deleteCalendarEventFromNotificationsList: (state, action: PayloadAction<string>) => {
      let calendarBadge = 0;
      const newList = state.notificationList[0].list.map((item) => {
        if (item.id === action.payload) {
          return { ...item, clicked: true };
        } else {
          if (item.clicked === false) {
            calendarBadge++;
          }
          return item;
        }
      });

      const newCalendarEventList = {
        ...state.notificationList[0],
        list: newList,
        badge: calendarBadge,
      };
      const newState = {
        ...state,
        notificationList: [
          newCalendarEventList,
          state.notificationList[1],
          state.notificationList[2],
        ],
        generalBadge:
          (newCalendarEventList.badge || 0) +
          (state.notificationList[1].badge || 0) +
          (state.notificationList[2].badge || 0),
      };
      saveNotificationsToLocalStorage(newState);
      return newState;
    },
    clearCalendarEventsFromNotificationsList: (state, action: PayloadAction<any>) => {
      const newCalendarEventList = {
        ...state.notificationList[0],
        list: [],
        badge: 0,
      };
      return {
        ...state,
        notificationList: [
          newCalendarEventList,
          state.notificationList[1],
          state.notificationList[2],
        ],
        generalBadge:
          0 + (state.notificationList[1].badge || 0) + (state.notificationList[2].badge || 0),
      };
    },
    addActionsToNotificationsList: (state, action: PayloadAction<any>) => {
      const newList = [...state.notificationList[1].list, action.payload];
      const newActionsList = {
        ...state.notificationList[1],
        list: newList,
        badge: newList.length,
      };
      const newState = {
        ...state,
        notificationList: [state.notificationList[0], newActionsList, state.notificationList[2]],
        generalBadge:
          (state.notificationList[0].badge || 0) +
          (newActionsList.badge || 0) +
          (state.notificationList[2].badge || 0),
      };
      saveNotificationsToLocalStorage(newState);
      return newState;
    },
    deleteActionsFromNotificationsList: (state, action: PayloadAction<string>) => {
      const newList = state.notificationList[1].list.filter((item) => item.id !== action.payload);
      const newActionsList = {
        ...state.notificationList[1],
        list: newList,
        badge: newList.length,
      };
      const newState = {
        ...state,
        notificationList: [state.notificationList[0], newActionsList, state.notificationList[2]],
        generalBadge:
          (state.notificationList[0].badge || 0) +
          (newActionsList.badge || 0) +
          (state.notificationList[2].badge || 0),
      };
      saveNotificationsToLocalStorage(newState);
      return newState;
    },
    addMessageToNotificationsList: (state, action: PayloadAction<any>) => {
      const newList = [...state.notificationList[2].list, action.payload];
      const newMessagesList = {
        ...state.notificationList[2],
        list: newList,
        badge: newList.length,
      };
      const newState = {
        ...state,
        notificationList: [state.notificationList[0], state.notificationList[1], newMessagesList],
        generalBadge:
          (state.notificationList[0].badge || 0) +
          (state.notificationList[1].badge || 0) +
          (newMessagesList.badge || 0),
      };
      saveNotificationsToLocalStorage(newState);
      return newState;
    },
    deletePatientMessagesFromNotificationsList: (state, action: PayloadAction<string>) => {
      const newList = state.notificationList[2].list.filter(
        (item) => item.chatUserId !== action.payload
      );
      const newMessagesList = {
        ...state.notificationList[2],
        list: newList,
        badge: newList.length,
      };
      const newState = {
        ...state,
        notificationList: [state.notificationList[0], state.notificationList[1], newMessagesList],
        generalBadge:
          (state.notificationList[0].badge || 0) +
          (state.notificationList[1].badge || 0) +
          (newMessagesList.badge || 0),
      };
      saveNotificationsToLocalStorage(newState);
      return newState;
    },
    setUid: (state, action: PayloadAction<string>) => {
      const newState = {
        ...state,
        uid: action.payload,
      };
      return newState;
    },
    setTenantId: (state, action: PayloadAction<string>) => {
      const newState = {
        ...state,
        tenantId: action.payload,
      };
      return newState;
    },
    markNotificationsAsRead: (state, action: PayloadAction<string>) => {
      if (action.payload === 'Messages') {
        const newMessagesList = {
          ...state.notificationList[2],
          list: [],
          badge: 0,
        };
        const newState = {
          ...state,
          notificationList: [state.notificationList[0], state.notificationList[1], newMessagesList],
          generalBadge:
            (state.notificationList[0].badge || 0) +
            (state.notificationList[1].badge || 0) +
            (newMessagesList.badge || 0),
        };
        saveNotificationsToLocalStorage(newState);
        return newState;
      }

      if (action.payload === 'Actions') {
        const newActionsList = {
          ...state.notificationList[1],
          list: [],
          badge: 0,
        };
        const newState = {
          ...state,
          notificationList: [state.notificationList[0], newActionsList, state.notificationList[2]],
          generalBadge:
            (state.notificationList[0].badge || 0) +
            (state.notificationList[2].badge || 0) +
            (newActionsList.badge || 0),
        };
        saveNotificationsToLocalStorage(newState);
        return newState;
      }

      if (action.payload === 'Calendar') {
        const newList = state.notificationList[0].list.map((item) => ({ ...item, clicked: true }));
        const newCalendarList = {
          ...state.notificationList[0],
          list: newList,
          badge: 0,
        };
        const newState = {
          ...state,
          notificationList: [newCalendarList, state.notificationList[1], state.notificationList[2]],
          generalBadge:
            (state.notificationList[2].badge || 0) +
            (state.notificationList[1].badge || 0) +
            (newCalendarList.badge || 0),
        };
        saveNotificationsToLocalStorage(newState);
        return newState;
      }

      return state;
    },
  },
});
export const {
  setInitialNotifications,
  addCalendarEventToNotificationsList,
  deleteCalendarEventFromNotificationsList,
  clearCalendarEventsFromNotificationsList,
  addActionsToNotificationsList,
  deleteActionsFromNotificationsList,
  addMessageToNotificationsList,
  deletePatientMessagesFromNotificationsList,
  setUid,
  setTenantId,
  markNotificationsAsRead,
} = notificationSlice.actions;
export const notificationListSelector = (state: RootState) => state.notifications.notificationList;
export const generalBadgeSelector = (state: RootState) => state.notifications.generalBadge;
export const uidSelector = (state: RootState) => state.notifications.uid;
export default notificationSlice.reducer;

function saveNotificationsToLocalStorage(state: NotificationState) {
  // do not save unread chat messages in local storage,
  // because we're fetching them from the API on app start
  saveState('notifications', excludeMessages(state));
}

export function loadNotificationsFromLocalStorage(): NotificationState | undefined {
  const state = loadState('notifications');
  if (!state) {
    return state;
  }

  // ignore unread messages already saved in user's local storage,
  // because we're fetching them from the API on app start
  return excludeMessages(state);
}

function excludeMessages(state: NotificationState): NotificationState {
  const [calendar, actions, messages] = state.notificationList;
  const result: NotificationState = {
    ...state,
    notificationList: [
      calendar,
      actions,
      {
        ...messages,
        list: [],
        badge: 0,
      },
    ],
    generalBadge: state.generalBadge - (messages?.badge || 0),
  };
  return result;
}
